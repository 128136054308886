import React, { useContext} from 'react'
import { graphql, Link } from 'gatsby'
import Header from "@common/menus/header"
import HeroMenu  from '@menus/HeroMenu'
import Seo from  "@components/Seo"
import HomeHeroSection from '@loggedOut/HomeHeroSection'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { GatsbyImage } from "gatsby-plugin-image";
import {FirebaseContext} from '@components/Firebase'

const CustomLink = styled(Link)`
  text-decoration:none;
`

const StoryListItem = styled.div`
  display:flex;
  height:75px;
  margin-bottom:10px;
  @media ${props =>props.theme.desktopUp} {
    margin-right:10px;
    width:400px;
  }
`

const StoryListWrapper = styled.div`
 @media ${props =>props.theme.desktopUp} {
  }
  display: flex;
  flex-wrap: wrap;
  width:100%;
  justify-content: start;
`


const StoryMeta= styled.p`
  font-size:12px;
  margin: 0px 0px;
  text-transform:uppercase;
  font-family: 'Poppins';
  color: #A68C8A;
  font-weight:500;
  max-width: 200px;
`

const StoryTitle= styled.h2`
  font-size:1rem!important;
  font-family: 'Poppins';
  margin:0px;
`
const CustomHeading = styled.h2`

`

const StoryInfoWrapper = styled.div`
  padding-left:20px;
  margin: auto 0px ;
 
`
const StorySectionWrapper = styled.div`
 @media ${props =>props.theme.desktopUp} {
    justify-content: start;
    width:75%;
  }
  display:flex;
  flex-direction:column;
  width:90%;
  justify-content: center;
  margin:auto;
`

const SeoLandingpageTemplate = (props) => {
  const { user } = useContext(FirebaseContext);
  const { t } = useTranslation()
  const lang = props.pageContext.lang



  const storiesInOrder = [...props.data.allStory.edges].sort((a, b) => 
    a.node.en.title.localeCompare(b.node.en.title)
  )

  return <>
    <Header location={props.location} />
    <>
      <Seo
        title={"Stories"}
        description={"Audio erotica for women & couples ✅ Short 5-20 min stories ✅ Respectful &amp; relatable ✅ Professional voice actors with sexy voices ✅ Explore new fantasies"}
      />
      {!user && <>
        <HeroMenu />
        <HomeHeroSection
          headerTitle={"Stories"}
          headerText={"Listen to our sensual erotic stories, allowing you to create a world entirely your own."}
          ctaText={t("seo-landingpage.cta-1")}
          showText
        />
      </>}

      <StorySectionWrapper>
      <CustomHeading>Stories:</CustomHeading>
        <StoryListWrapper>
          {storiesInOrder.sort().filter( story  => story.node.en.published).map(story => (
            <CustomLink to={`${t('urls.story')}${story.node[lang].slug}`} key={story.node.id}>
              <StoryListItem>
                <GatsbyImage image={story.node.localImage.smallImage.gatsbyImageData} />
                <StoryInfoWrapper>
                <StoryTitle>{story.node[lang].title}</StoryTitle>
                <StoryMeta>{story.node[lang].voices[0].name} • {story.node.scenario?.[lang].scenario}  • {story.node.mainFantasy[lang].fantasy}</StoryMeta> 
                </StoryInfoWrapper>
              </StoryListItem>
            </CustomLink>
          ))}
        </StoryListWrapper>
      </StorySectionWrapper>
    </>
  </>;
}

export const query = graphql`{
  allStory {
    edges {
      node {
        audiodesiresOriginal
        id
        en {
          duration
          featured
          freeAudioRef
          premiumAudioRef
          premiumContent
          published
          sampleAudioRef
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            id
            name
            gender
          }
        }
        es {
          duration
          featured
          freeAudioRef
          premiumAudioRef
          premiumContent
          published
          sampleAudioRef
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            id
            name
            gender
          }
        }
        de {
          duration
          featured
          freeAudioRef
          premiumAudioRef
          premiumContent
          published
          sampleAudioRef
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            id
            name
            gender
          }
        }
        localImage {
          smallImage: childImageSharp {
            gatsbyImageData(width: 75, height: 75, layout: FIXED)
          }
        }
        mainFantasy {
          en {
            fantasy
          }
          es {
            fantasy
          }
          de {
            fantasy
          }
        }
        fantasies {
          id
          en {
            fantasy
            slug
          }
          es {
            fantasy
            slug
          }
          de {
            fantasy
            slug
          }
        }
        genre {
          id
          en{
            genre
          }
          es{
            genre
          }
          de{
            genre
          }
          id
        }
        scenario {
          en {
            scenario
            slug
          }
          es {
            scenario
            slug
          }
          de {
            scenario
            slug
          }
        }
      }
    }
  }
}
` 




 
export default SeoLandingpageTemplate